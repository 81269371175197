import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
@Component({
  name: 'GtrEvalsModuleDesignView',
  components: {
    changelog: ChangeLog
  },
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('design', ['design', 'designFields', 'design_templates']),
    ...mapState('event', ['allContent', 'changelog', 'currentlyDeployedLiveUUID'])
  }
})
export default class GtrRegistrationModuleDesignView extends Vue {
  data () {
    return {
      submitting: false,
      designVersion: 'default',
      isLoading: true,
      event_uuid: this.$route.params.event_uuid,
      templateSettings: {
        template1: {
          background_image: {
            type: 'text',
            description: 'The full screen background image of the site',
            value: '',
            label: 'Background Image'
          }
        }
      },
      template: {},
      froalaEvents: {},
      registrationTypeKey: '_default',
      templateToUse: 'default',
      templateItems: [
        {
          text: 'Default',
          value: 'default'
        }
        // {
        //   text: 'Template 1',
        //   value: 'template1'
        // },
        // {
        //   text: 'Template 2',
        //   value: 'template2'
        // },
        // {
        //   text: 'Template 3',
        //   value: 'template3'
        // },
        // {
        //   text: 'Template 4',
        //   value: 'template4'
        // },
        // {
        //   text: 'Template 5',
        //   value: 'template5'
        // }
      ],
      froalaConfig: {
        codeMirrorOptions: {
          indentWithTabs: true,
          lineNumbers: true,
          lineWrapping: true,
          mode: 'text/html',
          tabMode: 'indent',
          tabSize: 2
        },
        events: {
          initialized: function () {
            // TODO
          },
          keyup: function () {
            // TODO
          }
        },
        enter: ''
      },
      designObject: {},
      yesNo: [
        { text: 'No', value: false },
        { text: 'Yes', value: true }
      ],
      _option_groups: [],
      _designFields: [],
      _changelog: null,
      _design: null,
      _currentlyDeployedLiveUUID: null,
      _currentlyDeployedDevUUID: null,
      prueba: null
    }
  }

  // #region Life Cycle Callbacks
  async mounted () {
    await this.fetchData()
  }
  // #endregion

  // #region Watchers
  @Watch('option_groups')
  onOptionGroupsChange (payload: any) {
    if (payload) {
      this.$data._option_groups = payload
    }
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload) {
      this.$data._changelog = payload
    }
  }

  @Watch('design')
  onDesignChange (payload: any) {
    if (payload) {
      this.$data._design = payload
    }
  }

  @Watch('designFields')
  onDesignFieldsChange (payload: any) {
    if (payload) {
      this.$data._designFields = payload
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload.live) {
      this.$data._currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  @Watch('designVersion')
  async onDesignVersionChange (payload: any) {
    if (payload !== 'default') {
      try {
        this.$data.isLoading = true
        const revisionUuid = this.$data._changelog.data.filter((row: any) => row.uuid === payload).pop().uuid
        await this.$store.dispatch('design/getSettingsByRevisionUuid', { event_uuid: this.$data.event_uuid, revision_uuid: revisionUuid })
        Container.get(Notification).success('Design version successfully changed.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.isLoading = false
      }
    }
  }

  @Watch('designLoaded', { deep: true })
  onDesignLoaded (payload: any) {
    if (payload) {
      const designObject = {}
      for (const category in this.$data._designFields) {
        for (const setting in this.$data._designFields[category]) {
          /**
           * Skip over the template setting
           * We are going to separate it from the rest of the designObject so that we can easily show/hide the default settings
           */
          if (setting === 'template') {
            continue
          }
          designObject[setting] = {}
          /**
           * Add type to the setting
           */
          designObject[setting].type = this.$data._designFields[category][setting].type
          designObject[setting].display_name = this.$data._designFields[category][setting].display_name
          designObject[setting].description = this.$data._designFields[category][setting].description
          designObject[setting].value = {}
          designObject[setting].temp = null
          if (this.$data._designFields[category][setting].type === 'select') {
            designObject[setting].values = this.$data._designFields[category][setting].values
          }
          for (let i = 0; i < this.registration_types.length; i++) {
            designObject[setting].value[this.registration_types[i].value] = ''
            if (this.$data._designFields[category][setting].default !== 'undefined') {
              if (this.$data._designFields[category][setting].type === 'url') {
                designObject[setting].value[this.registration_types[i].value] = this.$data._designFields[category][setting].default
                designObject[setting].value[this.parseSetting(this.registration_types[i].text) + '_temp_file'] = null
              } else {
                designObject[setting].value[this.registration_types[i].value] = this.$data._designFields[category][setting].default
              }
            }
          }
        }
      }
      /**
       * Fill the design object with values saved from the database
       */
      for (const setting in this.$data._design.page_data) {
        /**
         * Skip over the template setting and skip over settings that might have been removed
         */
        if (setting === 'template' || !designObject[setting]) {
          continue
        }
        for (const value in this.$data._design.page_data[setting]) {
          designObject[setting].value[value] = this.$data._design.page_data[setting][value]
        }
      }
      this.$data.designObject = designObject
      Object.keys(this.$data.designObject).map((key: string) => {
        if (this.$data.designObject[key].value._default === '') {
          if (this.$data.designObject[key].type === 'url') {
            this.$data.designObject[key].value._default = null
            this.$data.designObject[key].value.temp_file = null
          }
          if (this.$data.designObject[key].type !== 'css') {
            this.$data.designObject[key].value._default = null
          }
        } else {
          if (this.$data.designObject[key].type === 'url') {
            this.$data.designObject[key].value.temp_file = null
          }
        }
      })
      /**
       * Now set up the template data
       */

      /**
       * Add registration types
       */
      const templateObject = {}
      for (let i = 0; i < this.registration_types.length; i++) {
        templateObject[this.registration_types[i].value] = {
          current_template: 'default',
          template_data: {}
        }
        /**
         * Loop over the different templates and their settings and add them
         */
        for (const template in this.$data.templateSettings) {
          templateObject[this.registration_types[i].value].template_data[template] = {
            ...this.$data.templateSettings[template]
          }
        }
      }

      /**
       * Grab template settings from the database if it exists
       */
      if (this.$data._design.page_data.template && Object.keys(this.$data._design.page_data.template).length > 0) {
        for (let i = 0; i < this.registration_types.length; i++) {
          // need to loop over templates here
          templateObject[
            this.registration_types[i].value
          ].current_template = this.$data._design.page_data.template[
            this.registration_types[i].value
          ].current_template
          for (const template in this.$data.templateSettings) {
            templateObject[this.registration_types[i].value].template_data[
              template
            ] = Object.assign(
              {},
              templateObject[this.registration_types[i].value].template_data[template],
              this.$data._design.page_data.template[this.registration_types[i].value]
                .template_data[template]
            )
          }
        }
      }
      this.$data.template = templateObject

      /**
       * Set the template to use based on what was saved in the database
       * Grab the _default one because that is what registration type we initiate the page with
       */
      this.$data.templateToUse = this.$data.template._default.current_template
    }
  }
  // #endregion

  // #region Computed Props
  get registration_types () {
    if (Object.keys(this.$data._option_groups).length === 0) {
      return []
    }
    const registration_types_array = this.$data._option_groups
      .filter((group: any) => group.name === 'Registration Types')
      .shift().options
    const registration_types: any[] = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        text: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    registration_types.push({
      text: 'Default',
      value: '_default'
    })
    return registration_types
  }

  get registrationTypeItems () {
    return this.registration_types
  }

  get currentlyDeployedDevUUID () {
    /**
     * We can assume that the first index in the changelog data array is the most recent dev deploy
     *
     * Doing this saves us from making another api call
     */
    if (!this.$data._changelog.data || this.$data._changelog.data.length === 0) {
      return {}
    }
    return this.$data._changelog.data[0].uuid
  }

  get designLoaded () {
    const design = this.$data._design
    if (design) {
      this.$data._currentlyDeployedDevUUID = design.uuid
    }
    return (
      Object.keys(this.$data._designFields).length > 0 &&
      Object.keys(design).length > 0 &&
      this.registration_types.length > 0
    )
  }
  // #endregion

  // #region Methods
  getSettingsByTemplate (template: string) {
    const settings = {}
    Object.keys(this.$data.designObject).map((key: string) => {
      if (key.split('_')[0] === template) {
        settings[key] = this.$data.designObject[key]
      }
    })
    return settings
  }

  getItemsOfFontByTemplate (template: string) {
    const fonts: any[] = []
    if (this.$data._designFields) {
      if (this.$data._designFields[template]) {
        Object.keys(this.$data._designFields[template]).map((key: any) => {
          if (key.split('_')[0] === template && this.$data._designFields[template][key].type === 'font') {
            Object.keys(this.$data._designFields[template][key].options).map((option: any) => {
              const font: any = {
                value: option,
                text: this.$data._designFields[template][key].options[option]
              }
              if (!fonts.includes(font)) {
                fonts.push(font)
              }
            })
          }
        })
      }
    }
    return fonts
  }

  getColumnsByType (type: string) {
    if (type === 'css' || type === 'text' || type === 'html' || type === 'localized_html' || type === 'color' || type === 'font' || type === 'url') {
      return 12
    }
  }

  updateCurrentTemplateInTemplateObject () {
    this.$data.template[this.$data.registrationTypeKey].current_template = this.$data.templateToUse
  }

  updateDesignVersion (uuid: string) {
    this.$data.designVersion = uuid
  }

  capitalizeText (value: string) {
    return value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  }

  async submit () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      for (const key in this.$data.designObject) {
        if (key.split('_')[0] === this.$data.templateToUse) {
          if (this.$data.designObject[key].type === 'url') {
            for (const temp_file in this.$data.designObject[key].value) {
              if (temp_file.indexOf('temp_file') !== -1) {
                continue
              }
              const fileOrUrl = this.$data.designObject[key].value[temp_file]
              let url = fileOrUrl
              if (typeof fileOrUrl === 'object' && fileOrUrl instanceof File) {
                const response: any = await this.$store.dispatch('media/uploadMedia', { event_uuid: this.$route.params.event_uuid, fileData: fileOrUrl, fetch: false })
                url = response.responseEventMedia.data.url
              }
              this.$data.designObject[key].value[temp_file] = url
            }
          }
        }
      }

      const designData: any = {}
      // loop through every setting in the design object.
      for (const setting in this.$data.designObject) {
        if (this.$data.designObject[setting].type === 'url') { // if the type is a url.
          const temp = this.$data.designObject[setting].value // store the value of the setting.
          // setup a loop.
          let j = 0
          let count = Object.keys(temp).length

          // while j is less then count.
          while (j < count) {
            if (Object.keys(temp)[j].includes('temp')) { // if it has the word temp, delete it.
              delete temp[Object.keys(temp)[j]]
              count-- // decrement count.
            }
            j++ // increment j.
          }
          delete temp.temp_file
          designData[setting] = temp
        } else {
          designData[setting] = this.$data.designObject[setting].value
        }
      }
      /**
       * Add template stored in data() to the designData object
       */
      designData.template = this.$data.template
      designData.template_name = this.$data.templateToUse
      const payload: any = {
        event_uuid: this.$data.event_uuid,
        data: designData
      }
      await this.$store.dispatch('design/saveEvalsDesign', payload)
      Container.get(Notification).success('Design successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  private async fetchData () {
    try {
      this.$data.isLoading = true
      this.$store.commit('design/SET_DESIGN', [])
      this.$store.commit('design/SET_DESIGN_TEMPLATES', [])
      this.$store.commit('design/SET_DESIGN_FIELDS', [])
      this.$store.commit('option/SET_OPTION_GROUPS', [])
      await this.$store.dispatch('design/getEvalsDesign', { event_uuid: this.$data.event_uuid })
      await this.$store.dispatch('design/getDesignTemplates')
      await this.$store.dispatch('design/getEvalsDesignFields')
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$data.event_uuid })
      const changelogData = {
        type: 'evals_design',
        event_uuid: this.$data.event_uuid,
        subtype: 'default/all?no_data=true'
      }
      const deployLiveData = {
        type: 'evals_design',
        event_uuid: this.$data.event_uuid,
        sub_type: 'default'
      }
      this.$store.dispatch('event/getChangelog', changelogData)
      this.$store.dispatch('event/getCurrentlyDeployedLiveUUID', deployLiveData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.isLoading = false
    }
  }
  // #endregion

  /**
   * set the reg type value
   * @param setting - the setting
   * @param setting_key - the setting key
   * @param reg_type - the reg type
   * @param _template - currently not used in func, so removed from signature.
   */
  setRegTypeValue (setting: any, setting_key: string, reg_type: any) {
    const value = this.$data.designObject[setting_key]
    if (setting.type === 'url') {
      this.$data.designObject[setting_key].value[reg_type.value] = value.temp
      this.generateScreenshot(value.temp, setting_key, reg_type)
    } else if (setting.type === 'localized_html') {
      if (value.temp) {
        this.$data.designObject[setting_key].value[reg_type.value] = { en: value.temp }
      }
    } else {
      if (value.temp) {
        this.$data.designObject[setting_key].value[reg_type.value] = value.temp
      }
    }
  }

  parseSetting (value: any) {
    return value.split(' ').join('_').toLowerCase()
  }

  regTypeName (value: string) {
    const _value: any = this.registration_types.filter((reg_type: any) => reg_type.value === value)
    if (_value.length) {
      return this.capitalizeText(_value[0].text)
    }
  }

  regTypeValue (name: string) {
    const _value: any = this.registration_types.filter((reg_type: any) => reg_type.text.toLowerCase() === name)
    if (_value.length) {
      return _value[0].value
    }
  }

  generateScreenshot (fileOrUrl, settingName, regType) {
    if (!fileOrUrl) {
      return ''
    }
    if (typeof fileOrUrl !== 'object' && fileOrUrl instanceof File === false) {
      return fileOrUrl
    }
    const reader = new FileReader()
    reader.onload = (event) => {
      const id = `${settingName}-${regType.value}`
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      document.getElementById(id).src = event?.target?.result || ''
    }
    reader.readAsDataURL(fileOrUrl)
  }

  getFileName (file: File) {
    if (file) {
      return file.name
    }
  }

  getRegTypeValues (values: any) {
    const regValues: any = {}
    for (const regType in values) {
      if (regType.indexOf('temp_file') !== -1) {
        continue
      }
      if (values[regType] === '' || values[regType] === null) {
        continue
      }
      if (values[regType] && values[regType].en) {
        regValues[regType] = values[regType].en
      } else {
        regValues[regType] = values[regType]
      }
    }
    return regValues
  }

  setImageBase64 (file: File, reg_type_value: any, setting_key: string) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.$data.designObject[setting_key].value[reg_type_value] = reader.result
    }
  }

  /**
   * reuse a value.
   * @param setting - the stting
   * @param template - the template
   * @param value - the value
   */
  async reUseValue (setting: any, _template: any, value: any) {
    setting.temp = value
  }

  private async convertUrlToFile (url: string) {
    const response = await fetch(url)
    const blob = await response.blob()
    const fileExtension = url.split('.').pop()
    const file = new File([blob], 'image.' + fileExtension, { type: blob.type })
    return file
  }
}
